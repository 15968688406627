<template>
  <div class="sign-in d-flex flex-column align-center mx-4">
    <img class="ma-8"
      alt="Logo"
      src="../assets/logo-circle.svg"
      :width="48">
    <div class="text-h4 mb-4">{{ $t('__sign_in_title') }}</div>
    <v-card class="ma-2"
      outlined
      max-width="300"
      width="100%">
      <v-card-text class="pa-6">
        <v-form
          ref="form"
          v-model="valid">
          <div class="caption mb-2">{{ $t('__email_address') }}</div>
          <v-text-field
            outlined
            dense
            v-model="form.email"
            type="email"
            :rules="[ requiredRule, emailRule ]"
            required/>
          <div class="caption mb-2">{{ $t('__password') }}</div>
          <v-text-field
            outlined
            dense
            v-model="form.password"
            type="password"
            :rules="[ requiredRule ]"
            required/>
          <div class="caption mb-2 error--text">{{ error }}</div>
        </v-form>
        <v-btn
          block
          color="success"
          @click="signIn">
          {{ $t("__sign_in") }}
        </v-btn>
      </v-card-text>
    </v-card>
    <v-card class="ma-2"
      outlined
      max-width="300"
      width="100%">
      <v-card-text class="pa-6">
        <div class="caption">
          {{ $t('__new_to_chia_sign_up_message_1') }} 
          <a
            class="text-decoration-none"
            v-on:click="toSignUp">
            {{ $t('__new_to_chia_sign_up_message_2') }}
          </a>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'SignIn',
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      valid: false,
      error: '',
      requiredRule: v => !!v || this.$t('__required'),
      emailRule: v => /.+@.+\..+/.test(v) || this.$t('__email_address_invalid')
    }
  },
  methods: {
    signIn() {
      this.$refs.form.validate()
      if (this.valid) {
        this.$store.dispatch('setIsLoading', true)
        this.$store.dispatch('user/signInWithEmailAndPassword', {
          email: this.form.email,
          password: this.form.password
        })
        .catch(err => {
          this.$store.dispatch('setIsLoading', false)
          const errCode = err.code
          const errMessage = err.message
          let errorMessage = ''
          if (errCode) {
            const tKey = `__firebase_error_${errCode}`
            errorMessage = this.$t(tKey)
            if (errorMessage === tKey) {
              if (errMessage) {
                errorMessage = errMessage
              } else {
                errorMessage = errCode
              }
            }
          } else if (errMessage) {
            errorMessage = errMessage
          } else {
            errorMessage = err
          }
          this.error = errorMessage
        })
      }
    },
    toSignUp() {
      this.$router.push({ name: 'SignUp', query: this.$route.query })
    }
  },
  watch: {
    '$store.state.user.data': {
      immediate: true,
      handler(val) {
        if (val) {
          this.$store.dispatch('setIsLoading', false)
          if (this.$route.query.redirect) {
            this.$router.replace(this.$route.query.redirect)
          } else {
            this.$router.replace({ name: 'Home' })
          }
        }
      }
    }
  }
}
</script>